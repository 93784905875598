import dayjs from 'dayjs'

import { actionTypes, stateTypes, FisheyeCameraLens, FisheyeCameraLens_TM, ERROR_MAX, WARNING_MAX, UNKNOWN_CODE, OK_MAX, SUCCESS_CODE } from 'constants/live'
import { nsToTime, checkIsTM, setNumber } from 'utils/utils'

import ErrorIcon from 'remixicon-react/ErrorWarningLineIcon'
import InfoIcon from 'remixicon-react/InformationLineIcon'
import SuccessIcon from 'remixicon-react/CheckboxCircleLineIcon'
import WarningIcon from 'remixicon-react/AlertLineIcon'
import AddProfileIcon from 'remixicon-react/FileAddLineIcon'
import AddTranscoderIcon from 'remixicon-react/VideoAddLineIcon'
import ErrorWarningIcon from 'remixicon-react/ErrorWarningLineIcon'
import EditIcon from 'remixicon-react/PencilLineIcon'
import CopyIcon from 'remixicon-react/FileCopy2LineIcon'
import ExportIcon from 'remixicon-react/Upload2LineIcon'
import DeleteIcon from 'remixicon-react/DeleteBin6LineIcon'
import StartIcon from 'remixicon-react/LiveLineIcon'
import RestartIcon from 'remixicon-react/RestartLineIcon'
import StarIcon from 'remixicon-react/StarSLineIcon'
import StopIcon from 'remixicon-react/StopCircleLineIcon'
import SlateIcon from 'remixicon-react/ImageLineIcon'
import FilmLineIcon from 'remixicon-react/FilmLineIcon'

import FailedIcon from 'remixicon-react/CloseCircleFillIcon'
import CompleteIcon from 'remixicon-react/CheckboxCircleFillIcon'
import Loader from 'components/Loader'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'

/**
 * Live and Settings Action
 */
export const renderIcon = modalName => {
  const className = 'w-100 h-100'
  switch (modalName) {
    case actionTypes.edit:
    case actionTypes.update:
      return <EditIcon className={className} />
    case actionTypes.duplicate:
      return <CopyIcon className={className} />
    case actionTypes.export:
      return <ExportIcon className={className} />
    case actionTypes.delete:
      return <DeleteIcon className={className} />
    case actionTypes.markFinished:
      return <CheckLineIcon className={className} />
    case actionTypes.addProfile:
      return <AddProfileIcon className={className} />
    case actionTypes.start:
      return <StartIcon className={className} />
    case actionTypes.getStarted:
      return <AddTranscoderIcon className={className} />
    case actionTypes.restart:
      return <RestartIcon className={className} />
    case actionTypes.stop:
      return <StopIcon className={className} />
    case 'warning':
      return <ErrorWarningIcon className={className} />
    case 'set_default':
      return <StarIcon className={className} />
    case 'activate_slate':
      return <SlateIcon className={className} />
    case actionTypes.live2vod:
      return <FilmLineIcon className={className} />
    default:
      return null
  }
}

export const checkIsLive2VODComplete = ({ copy_destination: hasCopy, copy_result_url, stack_uuid }) => {
  if (hasCopy) return copy_result_url
  return !stack_uuid
}

export const renderLive2VODStatusIcon = record => {
  const { copy_destination: hasCopy, error, copy_error } = { ...record }

  if (hasCopy ? copy_error : error) {
    return (
      <span className='text-danger mb-auto'>
        <FailedIcon />
      </span>
    )
  }
  if (checkIsLive2VODComplete(record)) {
    return (
      <span className='color-primary mb-auto'>
        <CompleteIcon />
      </span>
    )
  }
  return (
    <div className='mb-auto' style={{ width: 24 }}>
      <Loader className='d-inline h-auto w-auto my-1' size='sm' />
    </div>
  )
}

export const renderButtonText = (modalName, requireRestart) => {
  switch (modalName) {
    case actionTypes.start:
      return 'launch'
    case actionTypes.update:
      return requireRestart ? 'restart' : 'update'
    default:
      return null
  }
}

export const setModalKey = (eventKey, subtitle) => (subtitle ? eventKey + subtitle : eventKey)

export const showEventName = output => {
  if (!output) return
  const key = `$\{EVENT}`
  const data = JSON.stringify(output)
  return data.includes(key)
}

export const getConfigType = ({ type, groups }) => {
  const isTM = checkIsTM(groups)
  switch (type) {
    default:
    case 'clearvr-pre-meshbox':
      return isTM ? 'Legacy' : 'ClearVR'
    case 'clearvr':
      return isTM ? 'Meshbox' : 'ClearVR'
    case 'clearvr-and-hls':
      return isTM ? 'Meshbox & HLS' : 'ClearVR & HLS'
    case 'hls':
      return 'HLS'
  }
}

export const isPreMeshbox = type => !type || type === 'clearvr-pre-meshbox'

export const setNewAccessUrl = (access_url, eventName) => access_url?.replace(/\${EVENT}/i, eventName)

export const getActionableLiveConfigs = activeTranscoders => {
  const actionableStackIds = activeTranscoders?.filter(({ state }) => state !== stopping)?.map(({ stack_uuid }) => stack_uuid)
  const actionableCount = actionableStackIds?.length
  return { actionableStackIds, actionableCount }
}

export const getMatchedData = (data, searchValue) => {
  const matchedData = data?.filter(({ name }) => name?.toLowerCase().includes(searchValue?.toLowerCase()))
  const matchedCount = matchedData.length
  return { matchedData, matchedCount }
}

export const checkSelectedLiveConfig = ({ currentId, selectedId }) => {
  if (!selectedId) return
  return currentId === selectedId || selectedId.includes(currentId)
}

const checkFisheyeChanges = (initialValues, values) => {
  const initialFisheye = initialValues?.camera_lens_enum
  const newFisheye = values?.camera_lens_enum
  return initialFisheye ? JSON.stringify(initialFisheye) !== JSON.stringify(newFisheye) : false
}

export const checkRestart = ({ type, dynamic_config }, values) => {
  const initialConfig = dynamic_config?.config_parameters
  const newConfig = values.dynamic_config?.config_parameters
  const isConfigChange = JSON.stringify(initialConfig) !== JSON.stringify(newConfig)

  // type === 'clearvr-pre-meshbox' (clearvr-encoding-platform-old-version)
  // type === 'clearvr' (clearvr-encoding-platform-new-version with meshbox)
  // type === 'clearvr-and-hls'
  const initialFisheye = dynamic_config?.runtime_parameters?.fish_eye_params
  const newFisheye = values.dynamic_config?.runtime_parameters?.fish_eye_params
  const isFisheyeChange = type === 'clearvr' || type === 'clearvr-and-hls' ? checkFisheyeChanges(initialFisheye, newFisheye) : false

  return isConfigChange || isFisheyeChange
}

export const checkFisheye = projection_type => {
  return projection_type?.startsWith('fish-eye')
}

export const setFishEyeValues = (projection_type, fish_eye_params) => {
  const isFisheye = checkFisheye(projection_type)
  if (!isFisheye) return null
  return {
    fish_eye_params: {
      camera_lens_enum: fish_eye_params?.camera_lens_enum || ''
    }
  }
}

/**
 * Live State
 */

const { preparing, deploying, reconfiguring, stopping, running, error } = stateTypes

export const checkIsActive = state => {
  switch (state) {
    case reconfiguring:
    case running:
    case error:
      return true
    default:
      return false
  }
}

export const setAlertProps = state => {
  switch (state) {
    case 'restart':
      return { type: 'warning', text: 'alert_warning' }
    case preparing:
    case deploying:
      return { type: 'info', text: 'alert_preparing' }
    // case reconfiguring:
    case stopping:
      return { type: 'info', text: 'alert_stopping' }
    case error:
      return { type: 'danger', text: 'alert_error' }
    default:
      break
  }
}

export const getTrafficLightColorFromStatus = status => {
  if (status === UNKNOWN_CODE) return 'unknown'
  if (status <= ERROR_MAX) return 'danger' // NotConnected
  if (status <= WARNING_MAX) return 'connected' // Connected
  if (status <= OK_MAX) return 'warning' // OK-WithNonFatalErrors
  if (status === SUCCESS_CODE) return 'success' // OK
  return 'unknown' // NaN or ?
}

export const showWarningIndicator = status => {
  return status > UNKNOWN_CODE && status <= WARNING_MAX
}

export const showErrorIndicator = status => {
  return status > UNKNOWN_CODE && status <= ERROR_MAX
}

export const renderAlertIcon = variant => {
  const size = 24
  switch (variant) {
    case 'danger':
      return <ErrorIcon size={size} />
    case 'info':
      return <InfoIcon size={size} />
    case 'success':
      return <SuccessIcon size={size} />
    case 'warning':
      return <WarningIcon size={size} />
    default:
      return null
  }
}

/**
 * Live Details
 */

const parseSRT = srt_dynamic_stats => [
  {
    title: 'connection',
    data: [{ 'Measured RTT (ms)': srt_dynamic_stats?.ms_rtt }, { 'Available bandwidth (Mbps)': srt_dynamic_stats?.mbps_bandwidth }]
  },
  {
    title: 'SRT info',
    data: [
      { 'Bytes dropped': ((srt_dynamic_stats?.byte_rcv_drop_total / srt_dynamic_stats?.byte_recv_total) * 100).toFixed(5) + '%' },
      { 'Bytes retransmitted': ((srt_dynamic_stats?.byte_retrans_total / srt_dynamic_stats?.byte_recv_total) * 100).toFixed(5) + '%' },
      { 'Bytes lost': ((srt_dynamic_stats?.byte_rcv_loss_total / srt_dynamic_stats?.byte_recv_total) * 100).toFixed(5) + '%' }
    ]
  }
]

const parseErrors = (text, errors) => {
  if (errors === undefined) return []
  return [{ [text]: errors }]
}

export const parseIngestStatus = ({ ingest_status }) =>
  ingest_status?.map(status => {
    const srtDynamicStats = status?.srt_stats ? parseSRT(status?.srt_stats?.srt_dynamic_stats) : []

    const ingestDetails = status?.mpeg2ts_stats
    const ingestErrors = [...parseErrors('PES errors', ingestDetails?.pes_packet_errors), ...parseErrors('CC errors', ingestDetails?.cc_errors)]
    return [
      {
        title: 'Dropped Frames',
        data: [{ 'Initial Samples': status?.num_dropped_initial_video_frames }, { Samples: status?.num_dropped_video_frames }, { 'Outdated Samples': status?.num_outdated_video_frames }]
      },
      {
        title: 'Duplicated Frames',
        data: [{ Unavailable: status?.num_duplicated_samples_because_unavailable }, { TimeJump: status?.num_duplicated_samples_because_time_jump }]
      },
      {
        title: 'Audio Samples (Duration)',
        data: [
          {
            Dropped: status?.dropped_duration_per_audio_track?.map(a => nsToTime(a))?.toString()
          },
          {
            'Injected Silence': status?.injected_silence_per_audio_track?.map(a => nsToTime(a))?.toString()
          }
        ]
      },
      {
        title: 'Corruption',
        data: [{ Discontinuities: status?.discontinuities }, ...ingestErrors]
      },
      {
        title: 'Received Info',
        data: [
          { 'Video Frames': setNumber(status?.received_video_frames) },
          { Bytes: formatBytes(status?.received_bytes) },
          { 'Video Duration': nsToTime(status?.received_video_duration) },
          {
            'Audio Duration': status?.received_audio_duration_per_track?.map(a => nsToTime(a))?.toString()
          }
        ]
      },
      ...srtDynamicStats
    ]
  })

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Byte'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const setFisheyeOptions = groups => (checkIsTM(groups) ? FisheyeCameraLens_TM : FisheyeCameraLens)

export const formateLogs = messages => messages.map(({ m, t }) => `[${dayjs(t).format('YYYY-MM-DD HH:mm:ss')}] ${m}`)

/**
 * Live Grafana Panels
 */

export const getOverviewStatusHeight = (panelId, props, content) => {
  // props for Live status panels
  // content for panels in Live Details Dialog
  let count
  const { multi_video, egress } = { ...content }
  switch (panelId) {
    case 32:
    case 33:
      count = props?.inputcount || multi_video?.length || 1
      break
    case 39:
    case 40:
      count = props?.outputcount || egress?.length || 1
      break
    default:
      count = 1
      break
  }

  const h = 18 * count + 32
  switch (panelId) {
    case 32:
    case 36:
    case 39:
      return h + 60
    case 33:
    case 37:
    case 40:
      return h
    default:
      break
  }
}

export const getCurrentStatusPanel = ({ id, isHLS }) => {
  switch (id) {
    case 7:
      return isHLS ? 41 : id
    case 9:
      return isHLS ? 42 : id
    case 19:
      return isHLS ? 43 : id
    case 20:
      return isHLS ? 44 : id
    case 33: // Panel without title
      return 34 // Panel with title
    case 37:
      return 35
    case 40:
      return 38
    default:
      return id
  }
}

export const isCurrentStatusPanel = id => {
  switch (id) {
    case 33:
    case 37:
    case 40:
      return true
    default:
      return false
  }
}
