import http from 'services/httpService'

// get a list of cameras
export const getConfigs = (customer_entrypoint, payload, source) => {
  const token = source && { cancelToken: source.token }
  return http.post_live(`${customer_entrypoint}/list`, payload, token)
}

// Start a LiveConfig Stack
export const startConfig = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/start`, payload)
}

// Stop a LiveConfig Stack
export const stopConfig = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/stop`, payload)
}

// Update LiveConfig Stack settings
export const updateConfig = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/control/update`, payload)
}

// Restart running LiveConfig Stack
export const restartConfig = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/control/restart`, payload)
}

// Append config to endlist
export const appendEndList = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/appendendlist`, payload)
}

// detailed status of a particular camera
export const getStatus = (customer_entrypoint, payload, source) => {
  const token = source && { cancelToken: source.token }
  return http.post_live(`${customer_entrypoint}/control/status`, payload, token)
}

// fetch ingest snapshots
export const getSnapshot = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/control/ingest_snapshot`, payload)
}

export const scheduleVODManifest = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/live2vod/schedule`, payload)
}

export const listVODManifests = (customer_entrypoint, payload) => {
  return http.post_live(`${customer_entrypoint}/live2vod/list`, payload)
}
