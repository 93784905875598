import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { openModal, setModalContent } from 'store/modal/modalSlice'

import { getMatchedData, getConfigType } from 'utils/liveUtils'
import { actionTypes } from 'constants/live'
import LiveAccordion from 'components/LiveAccordion'
import ActionDropdown from 'components/ActionDropdown'
import TranscoderCard from 'components/TranscoderCard'
import SearchInput from 'components/SearchInput'
import { appendEndLiveConfigs } from 'store/live/liveThunk'
import { toast } from 'react-toastify'

const LiveInactiveSection = ({ data, selectedInactiveIds, selectInactiveIds, searchValue, onChange, onCancel, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const groups = useSelector(({ user }) => user.groups)

  const dataCount = data.length
  const { matchedData, matchedCount } = getMatchedData(data, searchValue)
  const { start, startAll, checkConfig, live2vod, markFinished } = actionTypes

  const populateContent = data => data.map(({ state, ...rest }) => rest)

  const handleOpen = props => {
    selectInactiveIds([props?.config_uuid])
    const modalContent = populateContent([props])
    dispatch(setModalContent(modalContent[0]))
    dispatch(openModal(checkConfig))
  }

  const handleAddAll = () => {
    const modalContent = populateContent(matchedData)
    const allConfigIds = matchedData.map(({ config_uuid }) => config_uuid)
    selectInactiveIds(allConfigIds)
    dispatch(setModalContent(modalContent))
    dispatch(openModal(startAll))
    onChange('')
  }

  const getEventKeys = last_stack_uuid => [
    { eventKey: start, label: 'start_transcoder' },
    {
      eventKey: live2vod,
      label: 'Create VoD manifest',
      disabled: !last_stack_uuid
    },
    { eventKey: markFinished, label: 'mark_as_finished' }
  ]

  const handleSelect = async (eventKey, config_uuid, props) => {
    if (eventKey === markFinished.toLowerCase()) {
      try {
        const response = await dispatch(appendEndLiveConfigs({ configUUID: config_uuid, stackUUID: props.last_stack_uuid }))
        if (response?.meta.requestStatus === 'fulfilled') {
          toast.success('Successfully marked as finished')
        } else if (response?.meta.requestStatus === 'rejected') {
          toast.error('Failed to mark as finished')
        }
      } catch (e) {
        toast.error('Failed to mark as finished')
        console.error(e)
        return
      }
      return
    }

    selectInactiveIds([props.config_uuid])
    const content = populateContent([props])
    dispatch(setModalContent(content[0]))
    dispatch(openModal(eventKey))
  }

  return (
    <LiveAccordion eventkey='INACTIVE' dataCount={dataCount} disabled={!dataCount || !matchedCount} onClick={handleAddAll} {...props}>
      {dataCount > 1 && <SearchInput searchValue={searchValue} onChange={onChange} />}
      {matchedCount ? (
        matchedData.map(props => {
          const { config_uuid, last_stack_uuid, name, type } = { ...props }
          return (
            <article key={config_uuid} className='position-relative'>
              <TranscoderCard.Inactive key={config_uuid} name={name} type={getConfigType({ type, groups })} onOpen={() => handleOpen(props)} selected={selectedInactiveIds.includes(config_uuid)} />
              <ActionDropdown className='position-absolute top-0 end-0 m-1' eventKeys={getEventKeys(last_stack_uuid)} onSelect={eventKey => handleSelect(eventKey, config_uuid, props)} />
            </article>
          )
        })
      ) : (
        <p className='color-medium'>{data.length ? t('no_result') : t('no_inactive_transcoder')}</p>
      )}
    </LiveAccordion>
  )
}

export default LiveInactiveSection
